<script>
	const serviceName = "Veracy.app";
	const operatorName = "Attila Csermely";
	const lastUpdated = "2025-02-02";
	const contactEmail = "csermely.a.dev@gmail.com";

	$effect(() => {
		document.title = "Privacy Policy";
		return () => {
			document.title = "Veracy";
		};
	});
</script>

<div class="p-16 text-primary overflow-auto w-full">
	<h1 class="text-5xl font-bold mb-8">Privacy Policy</h1>
	<p class="text-sm text-secondary-foreground mb-8">
		Last Updated: {lastUpdated}
	</p>

	<!-- Introduction -->
	<section class="mb-8">
		<h2 class="text-2xl font-semibold mb-4">1. Introduction</h2>
		<p class="mb-4">
			This Privacy Policy explains how {serviceName} (“we,” “our,” or “the
			service”), operated by {operatorName}, collects, uses, and protects
			your data when you use our service to share and monetize content. By
			using the service, you agree to this policy.
		</p>
	</section>

	<!-- Information We Collect -->
	<section class="mb-8">
		<h2 class="text-2xl font-semibold mb-4">2. Information We Collect</h2>
		<p class="mb-4">We collect only the following data:</p>
		<ul class="list-disc pl-6 space-y-3">
			<li>
				<strong>RSA Public Keys:</strong> Generated by you or our platform
				for account authentication.
			</li>
			<li>
				<strong>Uploaded Images:</strong> Stored to enable public sharing
				or paid access via crypto payments. Images are compressed but not
				otherwise processed.
			</li>
			<li>
				<strong>Voluntary Feedback:</strong> Optional comments or suggestions
				you submit.
			</li>
			<li>
				<strong>Blockchain Metadata:</strong> Metadata stored permanently
				on Arweave.
			</li>
		</ul>
	</section>

	<!-- How We Use Your Data -->
	<section class="mb-8">
		<h2 class="text-2xl font-semibold mb-4">3. How We Use Your Data</h2>
		<ul class="list-disc pl-6 space-y-3">
			<li>
				<strong>Public Keys:</strong> Account authentication and security.
			</li>
			<li>
				<strong>Images:</strong> To deliver the service, including public
				sharing or paid access (verified via Arweave blockchain).
			</li>
			<li>
				<strong>Feedback:</strong> Service improvements.
			</li>
		</ul>
	</section>

	<!-- Data Storage & Security -->
	<section class="mb-8">
		<h2 class="text-2xl font-semibold mb-4">4. Data Storage & Security</h2>
		<div class="space-y-4">
			<div>
				<h3 class="font-semibold mb-2">Storage:</h3>
				<ul class="list-disc pl-6 space-y-2">
					<li>
						<strong>Images:</strong> Stored in EU-based database.
					</li>
					<li>
						<strong>Public keys: </strong> Stored in the same database
						and deleted when you remove them.
					</li>
					<li>
						<strong>Blockchain metadata: </strong> Permanently stored
						on Arweave (a third-party blockchain). We cannot modify or
						delete blockchain data.
					</li>
				</ul>
			</div>
			<div>
				<h3 class="font-semibold mb-2">Retention:</h3>
				<ul class="list-disc pl-6 space-y-2">
					<li>
						All data (except blockchain metadata) is deleted
						immediately when you delete your account.
					</li>
				</ul>
			</div>
		</div>
	</section>

	<!-- Data Sharing -->
	<section class="mb-8">
		<h2 class="text-2xl font-semibold mb-4">5. Data Sharing</h2>
		<ul class="list-disc pl-6 space-y-3">
			<li>
				<strong>Public Images:</strong> Images marked as public may be accessible
				to anyone.
			</li>
			<li>
				<strong>Paid Content:</strong> Images requiring payment are shared
				only after verifying a transaction on Arweave.
			</li>
			<li>
				<strong>Third Parties:</strong>
				<ul class="list-disc pl-6 space-y-3">
					<li>
						Arweave: Used to store permanent metadata (e.g., payment
						records).
					</li>
					<li>
						No other third parties receive your data unless required
						by law (e.g., court orders).
					</li>
				</ul>
			</li>
		</ul>
	</section>

	<!-- GDPR Rights -->
	<section class="mb-8">
		<h2 class="text-2xl font-semibold mb-4">
			6. Your Rights (GDPR Compliance)
		</h2>
		<div class="space-y-4">
			<ul class="list-disc pl-6 space-y-2">
				<p>You may:</p>
				<li>
					<strong>Delete Data:</strong> Remove images or your public key.
				</li>
				<li>
					<strong>Export Data:</strong> Request a copy of your stored data
					by contacting csermely.a.dev@gmail.com.
				</li>
				<li>
					<strong>Blockchain Limitation:</strong> Note that metadata on
					Arweave cannot be altered or deleted by us.
				</li>
			</ul>
			<div class="bg-secondary p-4 rounded-lg">
				<h3 class="font-semibold mb-2">Blockchain & GDPR Compliance</h3>
				<ul class="list-disc pl-6 space-y-2">
					<li>
						<strong>Nature of Blockchain Data:</strong>
						<ul class="list-disc pl-6 space-y-2">
							<li>
								Metadata stored on Arweave (e.g., payment
								transactions) is non-personal and anonymized to
								the extent possible.
							</li>
							<li>
								This data is technically required to verify
								payments and enable service functionality.
							</li>
						</ul>
					</li>
					<li>
						<strong>Your Acknowledgment:</strong>
						<ul class="list-disc pl-6 space-y-2">
							<li>
								You understand and agree that blockchain-stored
								data cannot be deleted due to the decentralized
								nature of Arweave, even if it contains
								transaction details.
							</li>
							<li>
								This limitation is inherent to blockchain
								technology and falls outside our control.
							</li>
						</ul>
					</li>
					<li>
						<strong>GDPR Rights:</strong>
						<ul class="list-disc pl-6 space-y-2">
							<li>
								While we uphold your GDPR rights (e.g., erasure
								of data stored on our servers), blockchain data
								is exempt from these rights due to technical
								immutability.
							</li>
						</ul>
					</li>
				</ul>
			</div>
		</div>
	</section>

	<!-- User Responsibilities -->
	<section class="mb-8">
		<h2 class="text-2xl font-semibold mb-4">7. User Responsibilities</h2>
		<ul class="list-disc pl-6 space-y-3">
			<li>
				<strong>Private Keys:</strong> You are solely responsible for safeguarding
				your RSA private key. We cannot recover lost keys.
			</li>
			<li>
				<strong>Content Restrictions:</strong>
				<ul class="list-disc pl-6 space-y-2">
					<li>
						Do not upload illegal, harmful, or infringing content
						(e.g., child exploitation, hate speech, or copyrighted
						material without permission).
					</li>
					<li>
						Age Ratings: You must label user-generated content with
						the appropriate age rating (e.g., 12+, 16+, 18+) based
						on its suitability for minors. Mislabeling may result in
						content removal or account suspension.
					</li>
				</ul>
			</li>
			<li>
				<strong>Compliance:</strong> We reserve the right to remove violating
				material and suspend accounts for breaches of these terms.
			</li>
		</ul>
	</section>

	<!-- Children’s Privacy -->
	<section class="mb-8">
		<h2 class="text-2xl font-semibold mb-4">8. Children’s Privacy</h2>
		<p>
			This service is not intended for users under 13. We do not knowingly
			collect data from minors.
		</p>
	</section>

	<!-- International Transfers -->
	<section class="mb-8">
		<h2 class="text-2xl font-semibold mb-4">
			9. International Data Transfers
		</h2>
		<p>Data stored and processed exclusively in the EU.</p>
	</section>

	<!-- Policy Updates -->
	<section class="mb-8">
		<h2 class="text-2xl font-semibold mb-4">10. Policy Updates</h2>
		<p>
			Changes will be notified via in-app alerts. Continued use
			constitutes acceptance.
		</p>
	</section>

	<!-- Contact -->
	<section class="mt-8 pt-6 border-t border-secondary">
		<h2 class="text-2xl font-semibold mb-4">Contact</h2>
		<p class="text-blue-600 hover:underline">
			<a href="mailto:{contactEmail}">{contactEmail}</a>
		</p>
	</section>
</div>
